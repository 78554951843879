import React, { Component } from 'react';
import MainMenu from '../main-menu/main-menu';

export default function withHeader(HeaderComponent) {
    
    return class HeaderWrapped extends Component {
        render() {
            return (
                <div>
                    <nav className="navbar navbar-inverse navbar-fixed-top bg-primary">
                        <div className="container">
                            <MainMenu/> 
                        </div>  
                    </nav>                   
                    <div className="container">   
                        <HeaderComponent/>
                    </div>  
                </div>
            )
        }
    }
}



