import React, { Component } from 'react';
import MatchScheduleService from '../../match-schedule-service';
import MatchGroupSelector from '../../match-group-selector/match-group-selector';
import Moment from 'react-moment';
import moment from 'moment'

class MatchGroup extends Component {

    constructor() {
        super();
        this.MatchScheduleService = new MatchScheduleService();
        this.state = {
            matchGroupItems: null,
            selectedMatchGroupItem: null,
            matches: [],
            deadlineMatch: null
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.matchGroupSelected = this.matchGroupSelected.bind(this)
    }

    componentWillMount() {
        this.MatchScheduleService.getMatchGroups()
            .then(res => {
                this.setState({matchGroupItems: res.items});
                
                if (res.items != null) {
                    this.setState({selectedMatchGroupItem: res.items[0]})
                    this.MatchScheduleService.getMatchSchedule(res.items[0].id)
                        .then(matches => {
                            this.setState({matches: matches.matches});
                            
                            // backend orders matches -> first match is deadline match
                            this.setState({deadlineMatch: matches.matches[0]});
                        })
                        .catch(err => {
                            // todo fix auth when not logged in
                            // alert(err);
                        })
                }
            })
            .catch(err => {
                // todo fix auth when not logged in
                // alert(err);
            })        
    }

    convertToPredictedResult(value) {
        if (value === 1) {
            return 'HomeVictory'
        }
        if (value === 3) {
            return 'Draw'
        }
        if (value === 2){
            return 'AwayVictory'
        }
    }

    isDisabled() {
        if (this.state.deadlineMatch === undefined ||
            this.state.deadlineMatch === null) {
            return false;
        }

        var now = moment();
        var deadlineMatch = moment(this.state.deadlineMatch.date);
        
        if (deadlineMatch < now) {
            return true;
        }
        return false;
    }

    disableSubmitButton() {       
        if (this.state.deadlineMatch === undefined ||
            this.state.deadlineMatch === null) {
            return false;
        }

        var now = moment();
        var deadlineMatch = moment(this.state.deadlineMatch.date);

        // deadline check
        if (deadlineMatch < now) {
            return true;
        }

        // all matches should be filled
        if (this.state.matches.find(x => x.predictedResult === 0)) {
            return true;
        }

        // doubler match should be selected
        if (this.state.matches.find(x => x.doubler === true) === undefined) {
            return true;
        }

        return false;
    }

    onSubmit(e) {
        e.preventDefault();
                
        const data = {
            Bets: [
                {
                    MatchId: this.state.matches[0].id,
                    PredictedResult: this.convertToPredictedResult(this.state.matches[0].predictedResult),
                    Doubler: this.state.matches[0].doubler
                },
                {
                    MatchId: this.state.matches[1].id,
                    PredictedResult: this.convertToPredictedResult(this.state.matches[1].predictedResult),
                    Doubler: this.state.matches[1].doubler
                },
                {
                    MatchId: this.state.matches[2].id,
                    PredictedResult: this.convertToPredictedResult(this.state.matches[2].predictedResult),
                    Doubler: this.state.matches[2].doubler
                },
                {
                    MatchId: this.state.matches[3].id,
                    PredictedResult: this.convertToPredictedResult(this.state.matches[3].predictedResult),
                    Doubler: this.state.matches[3].doubler
                },
                {
                    MatchId: this.state.matches[4].id,
                    PredictedResult: this.convertToPredictedResult(this.state.matches[4].predictedResult),
                    Doubler: this.state.matches[4].doubler
                },
            ]
        }
                
        this.MatchScheduleService.putBets(data)
            .then(res => {
                alert(res);
            })
            .catch(err => {
                alert(err);
            })        
    }   

    handleOptionChange(e, index, value) {
        if (this.isDisabled()) {
            return;
        }

        const matches = this.state.matches;

        // double pressed -> set as doubler
        if (matches[index].predictedResult === value) {
            this.setSelectedDoubler(e, index, value);
        }
        
        matches[index].predictedResult = value;

        this.setState({ matches: matches });
        this.setState({deadlineMatch: matches[0]});
    }

    matchGroupSelected(matchGroupItem) {
        this.setState({selectedMatchGroupItem: matchGroupItem})

        this.MatchScheduleService.getMatchSchedule(matchGroupItem.id)
            .then(matches => {
                this.setState({matches: matches.matches});
                this.setState({deadlineMatch: matches.matches[0]});
            })
            .catch(err => {
                alert(err);
            })
    }

    setDisabledButtonClass(match, buttonId) {
        if (match.result === buttonId || 
            (match.result === 0 && match.predictedResult === buttonId)) {                
            if (match.doubler === true) {
                return 'btn btn-warning disabled';
            } else {
                return 'btn btn-success disabled';
            }           
        }

        if (match.predictedResult === buttonId && match.predictedResult !== match.result) {
            return 'btn btn-danger disabled';
        }

        return 'btn disabled';
    }

    // buttonId == resultType (1, 2, 3)
    setButtonClass(index, buttonId) {
        // selected and doubler
        if (this.state.matches[index].predictedResult === buttonId &&
            this.state.matches[index].doubler === true) {
                return 'btn btn-warning';
        } 
        
        // selected
        if (this.state.matches[index].predictedResult === buttonId) {
                return 'btn btn-success';
        } 

        return 'btn btn-light';
    }

    setSelectedDoubler(e, index, value) {
        if (this.isDisabled()) {
            return;
        }

        const matches = this.state.matches;

        // clear other doubler
        matches.forEach(match => {
            match.doubler = false;
        });

        matches[index].doubler = true;

        this.setState({ matches: matches });
    }

    render() {      
        if (this.state.matches.length > 0) {
            const match = this.state.matches.map((match, index) => {
                if (this.isDisabled()) {
                    return(
                        <tr key={index}>
                            <td>
                                <small>
                                    <Moment format="DD-MM HH:mm">
                                        {match.date}
                                    </Moment>       
                                </small>                     
                            </td>
                            <td>
                                <small>{match.homeTeam} -</small>
                                <br/>
                                <small>{match.awayTeam}</small>
                            </td>
                            <td>
                                <div key={index} className="btn-group btn-group-justified" data-toggle="buttons">
                                    <label className={this.setDisabledButtonClass(match, 1)}>
                                        <input type="radio" name={index} id={index + "_1"} /> <small>{match.homeBet.toFixed(2)} </small>                                  
                                    </label>
                                    <label className={this.setDisabledButtonClass(match, 3)}>   
                                        <input type="radio" name={index} id={index + "_x"}/> <small>{match.drawBet.toFixed(2)} </small>
                                    </label>
                                    <label className={this.setDisabledButtonClass(match, 2)}>
                                        <input type="radio" name={index} id={index + "_2"}/> <small>{match.awayBet.toFixed(2)}</small>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    )
                } else {             
                    return(
                        <tr key={index}>
                            <td>
                                <small>
                                    <Moment format="DD-MM HH:mm">
                                        {match.date}
                                    </Moment>       
                                </small>                     
                            </td>
                            <td>
                                <small>{match.homeTeam} -</small>
                                <br/>
                                <small>{match.awayTeam}</small>
                            </td>
                            <td>
                                <div key={index} className="btn-group btn-group-justified" data-toggle="buttons">
                                    <label className={this.setButtonClass(index, 1)} 
                                        onClick={(e) => this.handleOptionChange(e, index, 1)}>
                                        <input type="radio" name={index} id={index + "_1"} /> <small>{match.homeBet.toFixed(2)} </small>                                  
                                    </label>
                                    <label className={this.setButtonClass(index, 3) + ' ml-1'}
                                        onClick={(e) => this.handleOptionChange(e, index, 3)}>   
                                        <input type="radio" name={index} id={index + "_x"}/> <small>{match.drawBet.toFixed(2)} </small>
                                    </label>
                                    <label className={this.setButtonClass(index, 2) + ' ml-1'}
                                        onClick={(e) => this.handleOptionChange(e, index, 2)}>
                                        <input type="radio" name={index} id={index + "_2"}/> <small>{match.awayBet.toFixed(2)}</small>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    )
                }
            })

            return(
                <div className="shadow-none p-3 mb-2 bg-light rounded">    
                    <div className="row mb-3">        
                        <div className="col-md-12">                
                            <div className="float-left">
                                <MatchGroupSelector click={this.matchGroupSelected} selected={this.state.selectedMatchGroupItem} items={this.state.matchGroupItems} />  
                            </div>
                            <div className="float-right text-right">
                                <h4>{this.state.selectedMatchGroupItem.name}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <form onSubmit={this.onSubmit}>
                                <table className="table table-striped">    
                                    <thead>
                                        <tr>
                                            <th className="w-5">Datum/tijd</th>
                                            <th>Wedstrijd</th>
                                            <th>
                                                <div>
                                                    <span>1</span>
                                                    <span className="pl-5 pr-5">x</span>
                                                    <span>2</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>                        
                                    <tbody>
                                        {match}                                    
                                    </tbody>                                                                   
                                </table>  
                                <button type="submit" className="btn btn-primary pull-right" disabled={this.disableSubmitButton()}>Opslaan</button>                
                            </form>
                        </div>
                    </div> 
                </div>      
            );
        }
        else {
            return null;
        }
    }
  }
  
  export default MatchGroup;