import React, { Component } from 'react';

class Logout extends Component {

    constructor(){
        super();

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.fbAsyncInit = function() {
            window.FB.init({
              appId      : '2212783915409962',
              cookie     : true,
              xfbml      : true,
              version    : 'v3.2'
            });

            window.FB.AppEvents.logPageView();                    
        };
        
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    };

    handleClick() {
        //Fetch the status so that we can log out.
        //You must have the login status before you can logout,
        //and if you authenticated via oAuth (server side), this is necessary.
        //If you logged in via the JavaScript SDK, you can simply call FB.logout()
        //once the login status is fetched, call handleSessionResponse
        window.FB.getLoginStatus(function(response) {
            if (!response.authResponse.accessToken) {
                this.props.history.replace('/login');
            } else {
                window.FB.logout(function(response) {                    
                    this.props.history.replace('/login')
                    return;
                });
            }
        });
    }

    render() {      

        return (
            <div>                
                <a className="nav-link" onClick={this.handleClick}>
                    Logout
                </a>
            </div>
        );
    }
}

export default Logout;