import React, { Component } from 'react';
import AuthService from '../auth/auth-service';
import RankingTable from './ranking-table/ranking-table';
import { compose } from 'recompose';
import withAuth from './../auth/with-auth';
import withHeader from './../header/with-header';

class RankingPage extends Component {
    
    constructor(){
        super();
        this.Auth = new AuthService(process.env.REACT_APP_API_URL);
    }

    render() {
        return (
            <div className="container">
                <RankingTable/>
            </div>
        );
    }
}

export default compose(
    withAuth,
    withHeader
)(RankingPage);