import React, { Component } from 'react';
import AuthService from '../auth/auth-service';
import MatchGroup from './match-table/match-group/match-group';
import { compose } from 'recompose';
import withAuth from './../auth/with-auth';
import withHeader from './../header/with-header';

class MatchSchedulePage extends Component {
    
    constructor(){
        super();
        this.Auth = new AuthService(process.env.REACT_APP_API_URL);
    }

    render() {
        return (
            <div className="container">
                <MatchGroup/>
            </div>
        );
    }
}

export default compose(
    withAuth,
    withHeader
)(MatchSchedulePage);