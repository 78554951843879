import React, { Component } from 'react';
import '../login/login.css';
import AuthService from '../auth-service';

class Login extends Component {

    constructor(){
        super();
        this.Auth = new AuthService(process.env.REACT_APP_API_URL);

        this.handleClick = this.handleClick.bind(this);
    }
    
    componentWillMount() {
        if(this.Auth.loggedIn())
            this.props.history.replace('/');
    }

    componentDidMount() {
        window.fbAsyncInit = function() {
            window.FB.init({
              appId      : '2212783915409962',
              cookie     : true,
              xfbml      : true,
              version    : 'v3.2'
            });

            window.FB.AppEvents.logPageView();   

            window.FB.getLoginStatus(function(response) {
                this.statusChangeCallback(response);
              }.bind(this));              
        }.bind(this);
        
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    };

    statusChangeCallback(response) {
        // The response object is returned with a status field that lets the
        // app know the current login status of the person.
        // Full docs on the response object can be found in the documentation
        // for FB.getLoginStatus().
        if (response.status === 'connected') {
            // Logged into your app and Facebook.
            this.Auth.login(response.authResponse.accessToken)
                    .then(res => {
                        this.props.history.replace('/');
                    })
                    .catch(err => {
                        alert(err);
                    });
        } else if (response.status === 'not_authorized') {
          // The person is logged into Facebook, but not your app.
          
        } else {
          // The person is not logged into Facebook, so we're not sure if
          // they are logged into this app or not.
          
        }
      }
    

    // This function is called when someone finishes with the Login
    // Button.  See the onlogin handler attached to it in the sample
    // code below.
    checkLoginState() {
        window.FB.getLoginStatus(function(response) {
            this.statusChangeCallback(response);
        }.bind(this));
    };

    handleClick() {
        window.FB.login(function(response) {
            this.statusChangeCallback(response);
        }.bind(this), {scope: 'public_profile,email'});
      }

    render() {
        return (
            <div className="h-100 d-flex justify-content-center align-items-center">                
                <button className="btnFacebook" onClick={this.handleClick}>
                    <i className="fa fa-facebook mr-2"></i>
                    Sign in with Facebook
                </button>
            </div>
        );
    }
}

export default Login;