import React, { Component } from 'react';
import AuthService from '../auth/auth-service';
import { Form, Text } from 'informed';
import MatchScheduleService from './match-schedule-service';

class CreateMatchSchedulePage extends Component {
    
    constructor(){
        super();
        this.Auth = new AuthService(process.env.REACT_APP_API_URL); 
        this.MatchScheduleService = new MatchScheduleService();

        this.state = {
            disableSubmit: false
        }
    }

    getRow(index) {
        return(                
            <div key={index} className="form-row">
                <div className="form-group col-md-2">    
                    <Text className="form-control" field={"date" + index} id={"date" + index} placeholder="Date" />
                </div>
                <div className="form-group col-md-1">    
                    <Text className="form-control" field={"time" + index} id={"time" + index} placeholder="Time" />
                </div>
                <div className="form-group col-md-3">                   
                    <Text className="form-control" field={"homeTeam" + index} id={"homeTeam" + index} placeholder="Thuis" />
                </div>
                <div className="form-group col-md-3"> 
                    <Text className="form-control" field={"awayTeam" + index} id={"awayTeam" + index} placeholder="Uit"/>
                </div>
                <div className="form-group col-md-1"> 
                    <Text className="form-control" field={"homeBet" + index} id={"homeBet" + index} placeholder="1"/>
                </div>
                <div className="form-group col-md-1"> 
                    <Text className="form-control" field={"drawBet" + index} id={"drawBet" + index} placeholder="x"/>
                </div>
                <div className="form-group col-md-1"> 
                    <Text className="form-control" field={"awayBet" + index} id={"awayBet" + index} placeholder="2"/>                    
                </div>
            </div>
        )
    }

    onSubmit( values ) {
        const data = {
            matchGroupName: values.name,
            matches: [
                {
                    homeTeam: values.homeTeam0,
                    awayTeam: values.awayTeam0,
                    homeBet: values.homeBet0,
                    awayBet: values.awayBet0,
                    drawBet: values.drawBet0,
                    date: new Date(values.date0 + 'T' + values.time0).toUTCString()
                },
                {
                    homeTeam: values.homeTeam1,
                    awayTeam: values.awayTeam1,
                    homeBet: values.homeBet1,
                    awayBet: values.awayBet1,
                    drawBet: values.drawBet1,
                    date: new Date(values.date1 + 'T' + values.time1).toUTCString()
                },
                {
                    homeTeam: values.homeTeam2,
                    awayTeam: values.awayTeam2,
                    homeBet: values.homeBet2,
                    awayBet: values.awayBet2,
                    drawBet: values.drawBet2,
                    date: new Date(values.date2 + 'T' + values.time2).toUTCString()
                },
                {
                    homeTeam: values.homeTeam3,
                    awayTeam: values.awayTeam3,
                    homeBet: values.homeBet3,
                    awayBet: values.awayBet3,
                    drawBet: values.drawBet3,
                    date: new Date(values.date3 + 'T' + values.time3).toUTCString()
                },
                {
                    homeTeam: values.homeTeam4,
                    awayTeam: values.awayTeam4,
                    homeBet: values.homeBet4,
                    awayBet: values.awayBet4,
                    drawBet: values.drawBet4,
                    date: new Date(values.date4 + 'T' + values.time4).toUTCString()
                },
            ]
        }
        
        this.MatchScheduleService.postMatchGroup(data)
            .then()
            .catch(err => {
                alert(err);
            })        
    }

    render() {
        let matchInput = [];
        for (let index = 0; index < 5; index++) {
            matchInput.push(this.getRow(index));
        }

        return (
            // ugly fix for now, remove when menu-button is added
            <div className="container">
                <h3>Ronde aanmaken</h3>
                <Form onSubmit={submittedValues => this.onSubmit(submittedValues)}>
                    {formApi => (
                        <div>
                            <div className="form-group"> 
                                <Text className="form-control" field="name" id="name" placeholder="Naam" />
                            </div>
                            {matchInput}
                            <div className="text-right">
                                <button type="submit" className="btn btn-primary">Opslaan</button>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        );
    }
}

export default CreateMatchSchedulePage;