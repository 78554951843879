import React, { Component } from 'react';
import RankingService from '../ranking-service';

class RankingTable extends Component {

    constructor() {
        super();
        this.RankingService = new RankingService();
        this.state = {
            items: []
        }
    }

    componentWillMount() {
        this.RankingService.getRankings()
            .then(res => {
                this.setState({items: res.items});
            })
            .catch(err => {
                // todo fix auth when not logged in
                // alert(err);
            })        
    }

    render() {      
        if (this.state.items.length > 0) {
            const item = this.state.items.map((item, index) => {
                return(
                    <tr key={index}>
                        <td>
                            {index+1}.
                        </td>
                        <td>
                            {item.name}
                        </td>
                        <td>
                            {item.points ? item.points.toFixed(2) : 0}
                        </td>
                        <td className="text-success">
                            {'+ ' + item.diffPoints.toFixed(2)}
                        </td>
                    </tr>
                )
            })

            return(
                <div>
                    <div className="row shadow-none p-3 bg-light rounded"> 
                        <div className="col-md-12">
                            <table className="table table-striped">       
                                <thead>
                                    <tr>
                                        <th>Positie</th>
                                        <th>Naam</th>
                                        <th>Punten</th>
                                        <th>Verschil</th>
                                    </tr>
                                </thead>                  
                                <tbody>
                                    {item}                                    
                                </tbody>                                                                   
                            </table>          
                        </div>
                    </div>  
                </div>    
            );
        }
        else {
            return null;
        }
    }
  }
  
  export default RankingTable;