import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'

class MenuItem extends Component {
  render() {

    const menuItem = this.props.menuItem;
    
    return  (
        <li className="nav-item active">
            <NavLink exact to={menuItem.link} className="nav-link">{menuItem.name}</NavLink>          
        </li>
    );
  }
}

MenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired
}

export default MenuItem;