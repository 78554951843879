import AuthService from '../auth/auth-service';

export default class RankingService {

    constructor() {
        this.Auth = new AuthService(process.env.REACT_APP_API_URL);
        this.domain = process.env.REACT_APP_API_URL;
    }

    getRankings() {      
        return this.Auth.fetch(`${this.domain}/api/rankings`, {
            method: 'GET'
        }).then(res => {
            return Promise.resolve(res);
        })
    }
}