import AuthService from '../auth/auth-service';

export default class MatchScheduleService {

    constructor() {
        this.Auth = new AuthService(process.env.REACT_APP_API_URL);
        this.domain = process.env.REACT_APP_API_URL;
    }

    getMatchGroups() {      
        return this.Auth.fetch(`${this.domain}/api/matches/match-groups`, {
            method: 'GET'
        }).then(res => {
            return Promise.resolve(res);
        })
    }

    getMatchSchedule(_matchGroupId) {
        return this.Auth.fetch(`${this.domain}/api/matches/schedule/${_matchGroupId}`, {
            method: 'GET'
        }).then(res => {
            return Promise.resolve(res);
        })
    }

    putBets(command) {
        return this.Auth.fetch(`${this.domain}/api/matches/schedule/place-bets`, {
            method: 'PUT',
            body: JSON.stringify(command)
        }).then(res => {
            return Promise.resolve(res);
        })
    }

    postMatchGroup(command) {
        return this.Auth.fetch(`${this.domain}/api/matches/match-groups`, {
            method: 'POST',
            body: JSON.stringify(command)
        }).then(res => {
            return Promise.resolve(res);
        })
    }
}