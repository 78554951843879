import React, { Component } from 'react';
import MenuItem from './menu-item/menu-item';
import './main-menu.css';
import Logout from '../auth/logout/logout';

export default class MainMenu extends Component {

  state = {
      menuItems:  [
          {
              name: 'Wedstrijdschema',
              link: '/schedule'
          },
          {
              name: 'Klassement',
              link: '/ranking'
          }
      ]
  }

  render() {
    const menuItems = this.state.menuItems.map(function(menuItem, index) {
        return(
          <MenuItem key={index} menuItem={menuItem} />
        )
    })

    return (
          <div className="navbar navbar-expand navbar-dark bg-primary w-100">              
              <ul className="nav navbar-nav mr-auto">
                  {menuItems}
              </ul>   
              {/* werkt nog niet optimaal. Dev geeft een facebook auth-url fout. Dit gaat op prod wel goed
              Op prod geeft hij een fout dat this.props.history niet bestaat */}
              {/* <ul className="nav navbar-nav">
                <li className="nav-item active">                    
                    <Logout/>
                </li>
              </ul> */}
          </div>
    )
  }
}