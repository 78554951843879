import React, { Component } from 'react';

export default class MatchGroupSelector extends Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, value){
        this.props.click(value);
    }

    render() {      
        if (this.props.items && this.props.items.length > 0) {
            const matchGroupItems = this.props.items.map((matchGroupItem, index) => {                
                return(
                    <a className="dropdown-item" key={index} onClick={(e) => this.handleClick(e, matchGroupItem)}>{matchGroupItem.name}</a>
                )
            })
            
            return(
                <div className="dropdown">
                    <a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Ronde
                    </a>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        {matchGroupItems}
                    </div>
                </div>        
            );
        } else {
            return null;
        }
    }
  }