import React, { Component } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import MatchSchedulePage from './matches/match-schedule-page';
import RankingPage from './ranking/ranking-page';
import CreateMatchSchedulePage from './matches/create-match-group-page';
import Login from './auth/login/login';

// const Auth = new AuthService(process.env.REACT_APP_API_URL);

class App extends Component {

    
  render() {
      return (   
        <Switch>
          <Route exact path='/' component={MatchSchedulePage} />   
          <Route exact path='/schedule' component={MatchSchedulePage} />   
          <Route exact path='/ranking' component={RankingPage} />             
          <Route exact path='/create-matchgroup' component={CreateMatchSchedulePage} />    
          <Route exact path='/login' component={Login} />     
        </Switch>          
      );
  }

  // handleLogout(){
  //     Auth.logout()
  //     this.props.history.replace('/login');
  // }
}

export default App;
